@charset 'UTF-8';

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    @include transform(translateX(8px));
  }
  33.3% {
    @include transform(translateX(-6px));
  }
  49.95% {
    @include transform(translateX(4px));
  }
  66.6% {
    @include transform(translateX(-2px));
  }
  83.25% {
    @include transform(translateX(1px));
  }
  100% {
    @include transform(translateX(0));
  }
}
@keyframes hvr-wobble-horizontal {
  16.65% {
    @include transform(translateX(8px));
  }
  33.3% {
    @include transform(translateX(-6px));
  }
  49.95% {
    @include transform(translateX(4px));
  }
  66.6% {
    @include transform(translateX(-2px));
  }
  83.25% {
    @include transform(translateX(1px));
  }
  100% {
    @include transform(translateX(0));
  }
}

#footer {
  position: relative;
  overflow: hidden;
  background: url($url_img_wpcontent+'common/f_bg.jpg') no-repeat center center;
  background-size: cover;
  .to-top {
    z-index: 999;
    display: none;
    @include PC {
      position: fixed;
      right: 36px;
      bottom: 33px;
    }
    a {
      display: inline-block;
      @include HOVER {
        &:hover {
          -webkit-animation-name: hvr-wobble-horizontal;
          animation-name: hvr-wobble-horizontal;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
        }
      }
    }
    @include SP {
      position: absolute;
      top: -37px;
      right: 19px;
      &.fix {
        position: fixed;
        top: auto;
        bottom: 20px;
      }
    }
  }
  .f-block01 {
    background: url($url_img_wpcontent+'common/shop_bg.jpg') no-repeat center center;
    background-size: cover;
    padding: 66px 0 58px;
    @include SP {
      padding: 58px 0 41px;
    }
    .list {
      .item {
        position: relative;
        @include SP {
          margin-bottom: 19px;
        }
        .img {
          overflow: hidden;
          margin-bottom: 12px;
        }
        .js-image {
          @include aspect-ratio(216, 144);
        }
        a {
          display: block;
          @include SP {
            @include flexbox();
            @include flex-wrap(wrap);
            .img {
              width: 39.525%;
              margin: 0;
            }
            .info {
              width: 60.475%;
              padding-left: 13px;
              padding: 5px 0 0 13px;
            }
            .btn {
              line-height: 1.6;
            }
          }
        }
      }
      .ttl {
        @include font(14,22,0,600);
        @include ffYM;
        margin-bottom: 5px;
        @include SP {
          margin-bottom: 7px;
          @include font(16,26,80);
        }
      }
      @include PC {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(center);
        .item {
          width: 21.6%;
          margin: 0 2%;
        }
      }
    }
  }
  .f-block02 {
    position: relative;
    padding: 42px 0 46px;
    @include PC {
      &-left {
        width: 32%;
        max-width: 320px;
        padding-top: 5px;
        .ipad &,
        .iPadPro11 &,
        .is-ipad129 & {
          width: 35%;
        }
      }
      &-right {
        width: 68%;
        padding-right: 10px;
        .ipad &,
        .iPadPro11 &,
        .is-ipad129 & {
          width: 65%;
        }
      }
      .container {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }
    }
    @include SP {
      padding: 44px 0 23px;
      .container {
        padding: 0;
      }
      &-left {
        padding: 0 20px 0 21px;
      }
      &-right {
        margin-top: 37px;
      }
    }

    .f-logo {
      a {
        display: inline-block;
      }
    }

    .f-info {
      color: #fff;
      margin-top: 23px;
      @include font(14,26,0,400);
      @include SP {
        margin-top: 21px;
      }
      .cm-tel-sp {
        color: #fff;
      }
    }
    .f-menu {
      li {
        a {
          @include ffYM;
          color: #fff;
          display: inline-block;
          @include font(14,17,200,400);
          @include PC {
            @include max-screen(990px) {
              @include font(12,15,170);
            }
          }
        }
      }
      @include PC {
        @include flexbox();
        @include justify-content(flex-end);
        li {
          a {
            padding: 0 26px;
            border-left: 1px solid #3A5064;
            @include max-screen(1024px) {
              padding: 0 18px;
            }
            @include max-screen(990px) {
              padding: 0 10px;
            }
          }
          &:first-child {
            a {
              padding-left: 0;
              border-left: 0;
            }
          }
          &:last-child {
            a {
              padding-right: 0;
            }
          }
        }
      }
      @include SP {
        @include flexbox();
        @include flex-wrap(wrap);
        li {
          border-bottom: 1px solid #3A5064;
          width: 50%;
          &.full {
            width: 100%;
            border-left: 0 !important;
          }
          a {
            @include font(16,19,200,400);
            display: block;
            text-align: center;
            padding: 17px 10px;
          }
          &:first-child {
            border-top: 1px solid #3A5064;
          }
          &:nth-child(2n+1) {
            border-left: 1px solid #3A5064;
          }
        }
      }
    }
    .f-box01 {
      .f-sns {
        text-align: center;
        a {
          display: inline-block;
          vertical-align: middle;
          margin: 0 12px;
        }
      }
      @include PC {
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items(center);
        padding-right: 5px;
        margin-top: 43px;
        .btn {
          width: 315px;
          order: 2;
          @include max-screen(990px) {
            width: 285px;
          }
        }
        .f-sns {
          order: 1;
          margin-right: 28px;
          text-align: center;
          a {
            display: inline-block;
            vertical-align: middle;
            margin: 0 12px;
          }
        }
      }
      @include SP {
        margin-top: 30px;
        .btn {
          width: 100%;
          margin:0 auto;
          max-width: 375px;
          padding: 0 20px;
          a {
            max-width: 100%;
          }
        }
        .f-sns {
          margin-top: 24px;
          a {
            margin: 0 17px;
            img {
              &.ico_line {
                width: 38px;
              }
              &.ico_ins {
                width: 32px;
              }
            }
          }
        }
      }
    }
  }
  .cp-right {
    text-align: right;
    padding-right: 14px;
    padding-bottom: 10px;
    color: #fff;
    @include font(12,20,0,400);
    @include SP {
      text-align: center;
      padding: 0 8px 8px;
    }
  }
}
.form-templ {
  .errMsg {
      margin-left: 1em;
      padding-bottom: 1px;
      display: block;
      line-height: 1.4;
      text-indent: -.9em;
  }

  .formError {
      padding-bottom: 13px;
      display: block;
  }

  .fixed {
      padding-bottom: 0px;
  }

  .formError .formErrorClose {
      border: solid #252525 2px;
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -4px;
      right: -4px;
      color: #efefef;
      background: #333;
      font-weight: bold;
      text-align: center;
      line-height: middle;
      cursor: pointer;
      z-index: 2;
      @include add_prefix(border-radius, 9px 9px 9px 9px);
      @include add_prefix(box-shadow, 1px -1px 3px #888);
  }

  .formError .formErrorClose:hover {
      background: #666;
  }

  .fixed .formErrorClose {
      display: none;
  }

  .formError .formErrorContent {
      padding: 6px 10px;
      width: 180px;
      position: relative;
      color: #fff;
      background: #252525;
      font-family: "Lucida Sans Unicode", "Lucida Grande", Arial, Helvetica, "ヒラギノ丸ゴ Pro W4", HiraMaruPro-W4, "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka, sans-serif;
      font-size: 11px;
      margin: 0 0;
      z-index: 1;
      @include add_prefix(border-radius, 3px);
      @include add_prefix(box-shadow, 0px 0px 6px #888);
  }

  .fixed .formErrorContent {
      margin-top: .5em;
      background: #f60;
      margin: 0 0 30px 0;
      @include add_prefix(border-radius, none);
      @include add_prefix(box-shadow, none);
  }

  .fadeOut {
      opacity: .2;
      filter: alpha(opacity=20);
  }

  .formError .formErrorArrow {
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: 0;
      left: 20px;
      margin: 0 0 30px 0;
      z-index: 0;
  }

  .fixed .formErrorArrow {
      display: none;
  }

  .formError .formErrorArrowBottom {
      margin: -6px;
      top: 0;
  }

  .fixed .formErrorArrowBottom {
      display: none;
  }

  .formError .formErrorArrow div {
      margin: 0 auto;
      display: block;
      height: 1px;
      background: #252525;
      line-height: 0px;
      font-size: 0px;
      @include add_prefix(box-shadow, 0px 2px 3px #888);
  }

  .formError .formErrorArrowBottom div {
      @include add_prefix(box-shadow, none);
  }

  .formError .formErrorArrow .line10 {
      width: 19px;
  }

  .formError .formErrorArrow .line9 {
      width: 17px;
  }

  .formError .formErrorArrow .line8 {
      width: 15px;
  }

  .formError .formErrorArrow .line7 {
      width: 13px;
  }

  .formError .formErrorArrow .line6 {
      width: 11px;
  }

  .formError .formErrorArrow .line5 {
      width: 9px;
  }

  .formError .formErrorArrow .line4 {
      width: 7px;
  }

  .formError .formErrorArrow .line3 {
      width: 5px;
  }

  .formError .formErrorArrow .line2 {
      width: 3px;
  }

  .formError .formErrorArrow .line1 {
      width: 1px;
  }

  .hid_url {
      display: none;
  }

  .form-main-title {
    background: #F5F5F5;
    text-align: center;
    @include font(34,48,0,700);
    padding: 70px 20px;
    margin-bottom: 100px;
    @include SP {
      @include font(26,37);
      padding: 30px 20px;
      margin-bottom: 34px;
    }
  }
  .contact-step {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    max-width: 800px;
    margin: 0 auto 60px;
    @include SP {
      margin-bottom: 23px;
    }
    .step-item {
      @include font(16,24,20);
      width: calc(33.33% - 20px);
      position: relative;
      text-align: center;
      height: 45px;
      background: url($url_img_wpcontent+'common/line_arrow2.svg') bottom left/100% auto no-repeat;
      @include SP {
        width: calc(33.33% - 10px);
        height: 54px;
        background-image: url($url_img_wpcontent+'common/line_arrow4.svg');
      }
      span {
        // @include ffMont;
        @include font(15,19,0,500);
        margin-right: 10px;
        @include SP {
          display: block;
        }
      }
      &.is-active {
        font-weight: 700;
        // color: $color_purple;
        background-image: url($url_img_wpcontent+'common/line_arrow.svg');
        @include SP {
          background-image: url($url_img_wpcontent+'common/line_arrow3.svg');
        }
        span {
          font-weight: 700;
        }
      }
    }
    &.step-02 {
      margin-bottom: 56px;
      @include SP {
        margin-bottom: 20px;
      }
    }
    &.step-03 {
      @include SP {
        margin-bottom: 52px;
      }
    }
  }
  .contact-box {
    .form-group {
      @include flexbox();
      @include flex-wrap(wrap);
      &:not(:last-child) {
        border-bottom: 1px solid #E0E0E0;
        @include SP {
          border-bottom: none;
        }
      }
      @include SP {
        margin: 0 -17px 0 -18px;
        &:last-child {
          border-bottom: 1px solid #ccc;
          padding-bottom: 5px;
        }
      }
    }
    .form-title {
      width: 28%;
      padding: 33px 30px;
      background: #F5F5F5;
      @include flexbox();
      @include align-items(baseline);
      @include SP {
        width: 100%;
        padding: 10px 17px 10px 18px;
      }
      em {
        font-style: normal;
        margin-right: 15px;
        @include font(14,14,100);
        text-align: center;
        width: 46px;
        height: 24px;
        line-height: 22px;
        font-weight: 700;
        display: inline-block;
        @include flex(0 0 auto);
        background: #E0E0E0;
        color: #000;
        .windows.ie & {
          padding-top: 2px;
        }
        &.required {
          // background: $color_purple;
          color: #fff;
        }
      }
      span {
        display: inline-block;
        @include font(16,24,20,700);
      }
    }
    .form-content {
      width: 72%;
      padding: 24px 30px 28px 48px;
      @include SP {
        width: 100%;
        padding: 20px 17px 27px 18px;
      }
      .note {
        @include font(12,17);
        margin-bottom: 13px;
        @include SP {
          margin-bottom: 11px;
        }
      }
      .form-input {
        width: 100%;
        height: 48px;
        border: 1px solid #ccc;
        @include border-radius(0px);
        background: #fff;
        outline: 0;
        -webkit-appearance: none;
        @include font(16,17,20);
        // @include ffNotosan;
        padding: 0 15px;
        @include SP {
          max-width: 100%;
          height: 48px;
        }
        &.w204 {
          max-width: 320px;
          @include SP {
            max-width: 100%;
          }
        }
      }
      textarea.form-input {
        height: 160px;
        display: block;
        padding: 10px 15px;
        @include SP {
          height: 150px;
        }
      }
      .box-checkbox {
        margin: -11px 0;
        padding-bottom: 15px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include SP {
          margin: -5px 0;
          padding-bottom: 2px;
        }
        p {
          width: 55%;
          @include flexbox();
          &:nth-child(2n) {
            width: 45%;
          }
          @include SP {
            width: 100% !important;
          }
        }
        >* {
          position: relative;
          margin: 11px 0;
          input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
          }
          label {
            @include flexbox();
            @include font(16,24,20);
            position: relative;
            .check {
              position: relative;
              width: 30px;
              height: 30px;
              margin-right: 15px;
              margin-top: -3px;
              @include flex(0 0 auto);
              &:before {
                content: '';
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                background: #fff;
                position: absolute;
                left: 0;
                top: 0;
              }
              &:after {
                content: '';
                position: absolute;
                top: 5px;
                width: 10px;
                height: 18px;
                left: 10px;
                border-left: 3px solid #fff;
                border-top: 3px solid #fff;
                @include rotate(-135);
                display: none;
              }
            }
          }
          input:checked + label {
            .check {
              &:before {
                // background: $color_purple;
              }
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }
    &.step-02 {
      .form-group {
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
      }
      .form-title {
        padding: 25px 30px;
        @include SP {
          padding: 10px 17px 10px 18px;
        }
      }
      .form-content {
        padding: 25px 40px;
        @include font(16,24);
        @include SP {
          padding: 15px 17px 15px 18px;
        }
        .i-res {
          span {
            &:not(:last-child) {
              &:after {
                content: ',';
                @include font(16,24);
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .contact-policy {
    margin: 58px 0 0;
    @include SP {
      margin-top: 26px;
    }
    ul {
      li {
        @include font(16,30,50);
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .contact-action {
    margin-top: 46px;
    @include SP {
      margin-top: 25px;
    }
    .btn-check {
      text-align: center;
      input {
        display: none;
      }
      label {
        display: block;
        @include font(16,28,0,700);
        padding: 26px 10px;
        border: 1px solid #C3BCD6;
        background: #F6F4FC;
        span {
          display: inline-block;
          position: relative;
          padding-left: 42px;
          .windows.ie & {
            padding-top: 3px;
          }
          &:before {
            content: '';
            width: 28px;
            height: 28px;
            border: 1px solid #ccc;
            background: #fff;
            position: absolute;
            left: 0;
            top: 0;
          }
          &:after {
            content: '';
            position: absolute;
            top: 3px;
            width: 10px;
            height: 18px;
            left: 10px;
            border-left: 3px solid #fff;
            border-top: 3px solid #fff;
            @include rotate(-135);
            display: none;
          }
        }
      }
      input:checked + label {
        span {
          &:before {
            // background: $color_purple;
          }
          &:after {
            display: block;
          }
        }
      }
    }
    .btn-submit {
      margin-top: 50px;
    }
    .btn-back {
      text-align: right;
      @include SP {
        margin-right: 8px;
      }
      a {
        @include font(12,17);
        display: inline-block;
        text-decoration: underline;
      }
    }
    &.step-02 {
      margin-top: 22px;
      @include SP {
        margin-top: 16px;
      }
      .btn-submit {
        @include SP {
          margin-top: 36px;
        }
      }
    }
  }
  .contact-bottom {
    text-align: center;
    @include font(14,26,100);
    margin-top: 100px;
    @include SP {
      margin-top: 46px;
      text-align: left;
    }
    a {
      text-decoration: underline;
    }
  }
  .contact-thanks {
    margin-top: -7px;
    padding-bottom: 80px;
    @include SP {
      padding-bottom: 56px;
    }
    .thanks-title {
      font-weight: 700;
      margin-bottom: 30px;
    }
    .thanks-ctn {
      margin-bottom: 65px;
      @include SP {
        margin-bottom: 38px;
      }
    }
    a {
      display: block;
      margin: 0 auto;
      font-weight: 500;
      text-decoration: underline;
      width: 120px;
      text-align: center;
    }
  }

  #footer.footer-form {
    margin-top: 100px;
    @include SP {
      margin-top: 40px;
    }
    .footer-info-box {
      background: transparent;
      padding-bottom: 40px;
      @include SP {
        padding-bottom: 20px;
      }
    }
  }
}
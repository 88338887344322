@charset 'UTF-8';
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2000;
  @include flexbox();
  @include justify-content(space-between);
  @include transition_c(background .3s);
  @include SP {
    height: 50px;
    background: #fff;
    overflow: initial;
  }
  &.header-fixed {
    background: #fff;
    @include box-shadow(3px, 0, 10px, rgba(0,0,0,.16));
    .header-logo {
      margin-left: 40px;
      @include min-screen(768px) {
        margin-left: 20px;
      }
      @include min-screen(992px) {
        margin-left: 40px;
      }
      @include SP {
        margin-left: 0;
      }
      .h-logo {
        a {
          img {
            &.default-logo {
              opacity: 0;
            }
            &.blue-logo {
              opacity: 1;
            }
          }
        }
      }
    }
    .header-gnavi {
      .menu {
        li {
          a {
            color: $color_black;
            &:before {
              background: $color_dark_blue;
            }
            &:after {
              background: rgba(51,51,51,.5);
            }
          }
        }
      }
    }
  }


  .header-logo {
    margin-left: 110px;
    padding-top: 20px;
    @include flex(0 0 auto);
    @include transition_c(.3s);
    @include min-screen(768px) {
      margin-left: 20px;
    }
    @include min-screen(992px) {
      margin-left: 80px;
    }
    @include min-screen(1200px) {
      margin-left: 110px;
    }
    @include SP {
      margin-left: 0;
      padding: 12px 10px;
      width: 42%;
    }
    .h-logo {
      a {
        line-height: 0;
        display: block;
        position: relative;
        img {
          @include transition_c(opacity .3s);
          @include min-screen(768px) {
            max-width: 100px;
            width: 100%;
          }
          @include min-screen(992px) {
            max-width: 166px;
          }
          @include SP {
            max-width: 127px;
            width: 100%;
          }
          &.default-logo {
            position: relative;
            z-index: 1;
            @include SP {
              opacity: 0 !important;
            }
          }
          &.blue-logo {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            opacity: 0;
            @include SP {
              display: block !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
  .header-right {
    width: 100%;
  }
  .h-button-shop {
    color: #fff;
    @include ffYM;
    @include font(14,20,200,500);
    background: $color_dark_blue;
    display: block;
    text-align: center;
    span {
      background: url($url_img_wpcontent+'common/icon/ico_cart.svg') left center/16px 16px no-repeat;
      display: inline-block;
      padding-left: 27px;
    }
  }
  .header-gnavi {
    @include min-screen(768px) {
      display: block !important;
    }
    @include SP {
      position: fixed;
      top: 0;
      right: 0;
      width: calc(100% - 50px);
      height: 100%;
      background: url($url_img_wpcontent+'common/menu_bg.jpg') 0 0/cover no-repeat;
      z-index: 3000;
      @include translate(100%,0);
      @include transition_c(transform .5s);
      &.is-open {
        @include translate(0,0);
        .hamberger {
          border: 1px solid $color_dark_blue;
        }
      }
      .hamberger {
        position: absolute;
        left: -50px;
        top: 0;
        z-index: 2000;        
      }
    }
    .header-gnavi-inner {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);
      @include SP {
        display: block;
        padding: 62px 30px;
        overflow-y: auto;
        height: 100%;
      }
    }
    .menu {
      @include flexbox();
      margin-right: 15px;
      @include SP {
        display: block;
        margin-right: 0;
      }
      li {
        padding: 0 15px;
        @include min-screen(768px) {
          padding: 0 5px;
        }
        @include min-screen(1200px) {
          padding: 0 15px;
        }
        @include SP {
          padding: 0;
          a {
            border-top: 1px solid #3A5064;
          }
        }
        a {
          display: inline-block;
          color: #fff;
          @include font(14,20,200,500);
          @include ffYM;
          position: relative;
          padding: 0 0 7px;
          @include min-screen(768px) {
            @include font(10)
          }
          @include min-screen(992px) {
            @include font(12);
          }
          @include min-screen(1200px) {
            @include font(14);
          }
          @include SP {
            color: #fff !important;
            @include font(16);
            display: block;
            text-align: center;
            border-bottom: 1px solid #3A5064;
            padding: 17px 10px;
          }
          @include ANDR {
            @include font(14);
          }
          &:after,
          &:before {
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            @include SP {
              display: none !important;
            }
          }
          &:after {
            width: 100%;
            opacity: 0;
            @include transition_c(opacity .3s);
            background: rgba(255,255,255,.5);
          }
          &:before {
            width: 0;
            background: #fff;
            @include transition_c(width .5s);
            transition-delay: .2s;
          }
          em.line {
            @include SP {
              display: none;
            }
          }
          @include HOVER {
            opacity: 1;
            &:hover {
              &:after {
                opacity: 1;
              }
              &:before {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .h-button-shop {
      max-width: 227px;
      width: 100%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      height: 70px;
      padding: 0 5px;
      @include min-screen(768px) {
        @include font(10);
        max-width: 150px;
        span {
          background-size: 10px 10px;
          padding-left: 17px;
        }
      }
      @include min-screen(1200px) {
        @include font(11);
        max-width: 150px;
        span {
          background-size: 12px 12px;
          padding-left: 20px;
        }
      }
      @include min-screen(1300px) {
        max-width: 227px;
        @include font(14);
        span {
          background-size: 16px 16px;
          padding-left: 27px;
        }
      }
      @include SP {
        max-width: 262px;
        border: 1px solid #fff;
        margin: 42px auto 0;
        background: transparent;
        @include font(16);
        span {
          padding-left: 40px;
        }
      }
      @include ANDR {
        @include font(14);
      }
    }
  }
  .header-mobile-right {
    @include min-screen(768px) {
      display: none !important;
    }
    height: 100%;
    .h-button-shop {
      width: calc(100% - 50px);
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      height: 100%;
      letter-spacing: 0;
      @include ANDR {
        @include font(12);
      }
    }
  }
  .hamberger {
    border: none;
    outline: none;
    cursor: pointer;
    background: #fff;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2000;
    @include transition_c(0.3s);
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    .burger-icon {
      @include burger(24px,1px,6px,$color_black);
    }
    &.is-active {
      .burger-icon {
        @include burger-to-cross;
      }
    }
  }
}
@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent {
  -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;
  ul,ol {

  }
  ul {
    margin-bottom: 30px;
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      position: relative;
      list-style: none;
      @include font(16,34,80,400);
      padding-left: 35px;
      margin-bottom: 4px;
      @include SP {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @include SP {
        @include font(14,24);
      }
      &:before{
        position: absolute;
        content: "・";
        width: 16px;
        height: 16px;
        color: $color_dark_blue;
        top: 0;
        left: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  ol {
    margin-bottom: 30px;
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
      @include font(16,34,80,400);
      margin-left: 22px;
      padding-left: 13px;
      margin-bottom: 4px;
      @include SP {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @include SP {
        @include font(14,24);
      }
      &::marker {
        color: $color_dark_blue;
        font-weight: 500;
      }
    }
  }
  iframe {max-width: 100%!important;}
  img{
    max-width:100%;
    height:auto;
    max-height:100%;
    width: auto;
  }
  a {
    text-decoration: underline;
    color: #1F5F96;
    @include HOVER {
      &:hover{
        text-decoration: none;
      }
    }
  }
  h2 {
    color: #fff;
    @include font(26,45,200,500);
    @include ffYM;
    position: relative;
    background: $color_dark_blue;
    padding-top: 15px;
    padding-bottom: 14px;
    margin-bottom: 51px;
    @include PC {
      @include max-screen(992px) {
        @include font(24,42);
      }
    }
    @include SP {
      @include font(20,34);
      padding-top: 20px;
      padding-bottom: 18px;
      margin-bottom: 24px;
    }
  }
  h3 {
    position: relative;
    color: $color_black;
    @include ffYM;
    @include font(20,38,200,500);
    &:before {
      position: absolute; content: "";
      top: 18px; right: 100%;
      background: $color_black;
      width: 154px; height: 1px;
      margin-right: 30px;
    }
    @include SP {
      padding-left: 25px;
      &:before {
        left: 0; top: 16px;
        width: 15px;
        margin-right: 0;
      }
      @include font(18,34);
      @include ANDR {
        @include font(17,34);
      }
      @include IP5 {
        @include font(16,32);
      }
    }
  }
  p {
    @include font(16,34,80,400);
    @include SP {
      @include font(14,28);
    }
    + p {
      // margin-top: 34px;
      // @include SP {
      //   margin-top: 29px;
      // }
    }
    + h2 {
      margin-top: 76px;
      @include SP {
        margin-top: 40px;
      }
    }
    + ol, + ul  {
      margin-top: 27px;
      @include SP {
        margin-top: 27px;
      }
    }
    span[style*="text-decoration: underline;"] {
      text-decoration: none!important;
      background-image: -webkit-linear-gradient(30deg, #E2DC64 0%, #E2DC64 100%);
      background-image: -o-linear-gradient(30deg, #E2DC64 0%, #E2DC64 100%);
      background-image: linear-gradient(30deg, #E2DC64 0%, #E2DC64 100%);
      background-repeat: no-repeat;
      background-size: 100% 2px;
      background-position: 0 100%;
      padding-bottom: 5px;
    }
  }

  blockquote {
    position: relative;
    border: 1px solid $color_gray2;
    @include font(16,34,80,400);
    &:before {
      content: "";
      position: absolute;
      z-index: 22;
      top: -30px;
      left: 32px;
      width: 54px;
      height: 43px;
      background: url($url_img_wpcontent+'common/icon/ico_quote.svg') no-repeat left center / 54px 43px;
    }
    p {
      @include font(16,34,80,400);
      // margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include PC {
      color: $color_black;
      padding: 31px 41px 19px 48px;
      margin-top: 70px;
      margin-bottom: 63px;
      @include max-screen(1024px) {
        padding: 30px 25px 10px 25px;
        margin-top: 55px;
        margin-bottom: 40px;
      }
    }
    @include SP {
      padding: 21px 19px 17px 19px;
      margin-top: 42px;
      margin-bottom: 36px;
      @include font(14,28);
      &:before {
        top: -12px;
        width: 35px;
        height: 29px;
        background: url($url_img_wpcontent+'common/icon/ico_quote.svg') no-repeat left center / 35px 29px;
      }
      p {
        margin-bottom: 28px;
        @include font(14,28);
      }
      @include IP5 {
        margin-top: 45px;
        padding: 27px 15px 13px 15px;
        margin-bottom: 28px;
      }
    }
  }

  .ogp-form {
    position: relative;
    border: 1px solid #707070;
    padding: 15px 20px 25px;
    max-width: 360px;
    margin: 38px 0 20px;
    line-height: 0;
    @include SP {
      padding: 10px 20px 15px;
      margin: 30px 0 30px;
    }
    .ogp-title {
      @include font(22,34,80,400);
      word-break: break-all;
      @include SP {
        @include font(20,30);
      }
      a {
        text-decoration: none;
        color: $color_black;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
    .ogp-description {
      color: $color_black;
      word-break: break-word;
      white-space: break-spaces;
      @include font(15,20,80,400);
      margin-top: 12px;
      @include SP {
        @include font(14,22);
      }
    }
    figure {
      margin-top: 24px;
    }
  }
}

.wp-pagenavi {
  text-align: center;
  letter-spacing: 0;
  font-size: 0;
  margin-top: 38px;
  @include SP {
    margin-top: 58px;
  }
  .pages {
    display: none;
  }
  .first, .last {
    display: none;
  }
  a, span {
    display: inline-block;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    line-height: 33px;
    border: 1px solid $color_dark_blue;
    @include ffBenne;
    @include font-size(20);
    @include letter-spacing(80);
    font-weight: 500;
    color: $color_dark_blue;
    margin: 4px 4px;
    padding-left: 2px;
    @include add_prefix(transition, 0.3s ease all);

    @include HOVER {
      &:hover {
        opacity: 1;
        color: #fff;
        background: $color_dark_blue;
      }
    }
    &.current {
      color: #fff;
      background: $color_dark_blue;
    }
    &.extend {
      background: none;
      border: none;
      color: $color_dark_blue;
      font-weight: bold;
      @include font-size(22);
    }

  }
  .previouspostslink, .nextpostslink {
    border: none;
    background: none!important;
    text-indent: -9999px;
    width: 55px; height: 35px;
    position: relative;
    &:before, &:after {
      position: absolute; content: "";
      bottom: 50%;
      margin-bottom: -3px;
      background: $color_dark_blue;
      height: 1px;
    }
    &:before {
      left: 0; width: 100%;
    }
    &:after {
      width: 10px;
      transform-origin: bottom right;
    }
  }
  .previouspostslink {
    &:after {
      left: 0;
      @include transform(rotate(-40deg));
      transform-origin: bottom left;
    }
    @include HOVER {
      &:hover {
        opacity: 1;
        @include transform(translateX(-10px));
      }
    }
  }
  .nextpostslink {
    &:after {
      right: 0;
      @include transform(rotate(40deg));
      transform-origin: bottom right;
    }
    @include HOVER {
      &:hover {
        opacity: 1;
        @include transform(translateX(10px));
      }
    }
  }

  &.pagenavi-sp {
    @include PC {
      display: none;
    }
    @include SP {
      position: relative;
      max-width: 270px;
      margin: 58px auto 0;
      text-align: center;
      min-height: 55px;
      .wp-pagenavi-sp__select {
        width: 120px;
        border: 1px solid $color_black;
        position: relative;
        background: #fff;
        margin: 0 auto;
        @include IP5 {

        }
      }
      select {
        width: 120px;
        padding: 15px 25px;
        -ms-text-align-last: center;
        -moz-text-align-last: center;
        -webkit-text-align-last: center;
        text-align-last: center;
        text-align: justify;
        @include font(16,20,80);
        @include ffBenne;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @include border-radius(0px);
        position: relative;
        z-index: 1;
        background: none!important;
        @include IP5 {
          width: 110px;
          padding: 0 22px;
        }
      }
      .ghostSelect {
        opacity: 0;
        display: block;
        position: absolute;
      }
      .value-selected {
        padding: 16px 20px;
        @include ffBenne;
        color: $color_black;
        @include font(20,20,80);
        .num-page {
          font-style: normal;
        }
        .total-pages {
          width: auto;height: auto;
          position: relative;
          display: inline-block;
          vertical-align: top;
          @include font(20,20,80);
          color: $color_black;
          padding-left: 13px;
          margin: 0px 0 0 9px;
          border: none;
          &:before {
            padding-right: 5px;
            position: absolute;
            content: "/";
            left: 0;
            top: 1px; width: 10px;
            height: 17px;
            font-size: 20px;
            @include font(20,20,80);
            color: $color_black;
          }
        }
      }

      .previouspostslink, .nextpostslink {
        position: absolute;
        top: 4px;
      }
      .previouspostslink {
        left: 0;
      }
      .nextpostslink {
        right: 0;
      }

      // select {
      //   width: 120px;
      //   height: 55px;
      //   padding: 0 20px;
      //   padding-top: 4px;
      //   border: 1px solid $color_black;
      //   // text-align: center;
      //   -ms-text-align-last: center;
      //   -moz-text-align-last: center;
      //   -webkit-text-align-last: center;
      //   text-align-last: center;
      //   // direction: rtl;
      //   text-align: justify;

      //   -webkit-appearance: none;
      //   -moz-appearance: none;
      //   appearance: none;
      //   border-radius: 0;
      //   position: relative;
      //   z-index: 1;
      //   background: none;
      // }

    }
  }
}

.mod-pagination {
  border-top: 1px solid #E3E3E3;
  padding: 60px 0 0;
  @include PC {
    min-height: 260px;
  }
  @include SP {
    padding-top: 29px;
    .inner {
      margin-bottom: 20px;
    }
  }
  .block {
    position: relative;
    min-height: 60px;
  }
  .paging {
    display: block;
    &.hover-zoom01 {
      overflow: inherit;
    }
    @include PC {
      width: calc(50% - 160px);
      max-width: 227px;
    }
    @include SP {
      width: 46.41%;
    }
    .new-label {
      width: 40px;
    }
    .thumb {
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
      @include SP {
        margin-top: 8px;
        margin-bottom: 14px;
      }
    }
    .js-image {
      @include aspect-ratio(227, 152);
    }
    .ttl {
      @include font(16,26,40,500);
      @include ffYM;
      @include SP {
        margin-bottom: 8px;
        @include font(14,24);
      }
    }
    .cm-btn01 {
      text-decoration: none;
      @include ffBenne;
      @include font(12,13,80);
      .cm-arr01 {
        width: 36px;
        height: 6px;
        margin-left: 8px;
        margin-bottom: 3px;
        @include add_prefix(transition, 0.3s ease all);
        &:after {
          width: 6px;
        }
      }
    }
    &.list {
      width: 33.33%;
      max-width: 315px;
      @include PC {
        position: absolute;
        left: 50%;
        top: 75px;
        margin-left: -158px;
      }
      @include SP {
        width: 85%;
        max-width: 280px;
        position: relative;
        top: 0!important;
        margin: 18px auto 0;
      }
    }
    &.prev {
      float: left;
      .cm-arr01 {
        margin-left: 0;
        margin-right: 8px;
        &:after {
          left: 0;
          width: 10px;
          @include transform(rotate(-40deg));
          transform-origin: bottom left;
        }
      }
      @include HOVER {
        &:hover {
          .cm-arr01 {
            @include transform(translateX(-5px));
          }
        }
      }
    }
    &.next {
      float: right;
      .btn {
        text-align: right;
      }
      @include HOVER {
        &:hover {
          .cm-arr01 {
            @include transform(translateX(5px));
          }
        }
      }
    }
  }
}

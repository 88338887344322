@charset 'UTF-8';
.subpage {
  #header {
    background: #fff !important;
    .header-logo {
      margin-left: 40px;
      @include min-screen(768px) {
        margin-left: 20px;
      }
      @include min-screen(992px) {
        margin-left: 40px;
      }
      @include SP {
        margin-left: 0;
      }
      .h-logo {
        a {
          img {
            &.default-logo {
              opacity: 0 !important;
            }
            &.blue-logo {
              opacity: 1 !important;
            }
          }
        }
      }
    }
    .header-gnavi {
      .menu {
        li {
          a {
            color: $color_black;
            &:before {
              background: $color_dark_blue;
            }
            &:after {
              background: rgba(51,51,51,.5);
            }
          }
        }
      }
    }
  }
  #wrap {
    padding-top: 70px;
    @include SP {
      padding-top: 50px;
    }
  }
}
.slick-dots {
  line-height: 0;
  font-size: 0;
  top: 1px;
  right: 87px;
  width: 180px;
  li {
    margin: 0 7px;
    button {
      width: 5px;
      height: 5px;
      background: $color_dark_blue;
      opacity: .4;
      padding: 0;
      @include border-radius(0);
    }
    &.slick-active {
      button {
        opacity: 1;
      }
    }
  }
}
.js-image,
.js-switch-image {
  display: block;
  background: #EFF0F2 center no-repeat;
  background-size: cover;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  img {
    position: absolute;
    left: -9999px;
    backface-visibility: hidden;
  }
  &.is-noimg {
    background-position: center center;
    background-size: 100% auto;
  }
}
.js-abs-image {
  @include flexbox();
  @include flex-wrap(wrap);
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.comming {
  text-align: center;
  padding: 0;
  @include SP {
    padding: 60px 0 0;
  }
}

/* BREADCRUMB
-------------------------------------------------------------*/
.breadcrumb {
  padding: 16px 0;
  @include SP {
    padding: 11px 0;
  }
  ul {
    color: $color_black;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    li {
      display: inline;
      @include font(12,18,80,500);
      position: relative;
      padding-right: 22px;
      color: $color_black;
      &:before {
        content: "";
        position: absolute;
        right: 7px;
        top: 30%;
        width: 5px;
        height: 5px;
        border-left: 1px solid #E3E3E3;
        border-top: 1px solid #E3E3E3;
        @include transform(rotate(135deg));
      }
      &:last-child {
        padding-right: 0;
        &:before {
          display: none;
          background: none;
        }
      }
    }
    a {
      color: $color_black;
      display: inline-block;
      text-decoration: underline;
      @include HOVER {
        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }
  }
}

/* HOVER
-------------------------------------------------------------*/
.hover-zoom01 {
  display: block;
  overflow: hidden;
  opacity: 1;
  .img-zoom {
    overflow: hidden;
    > img {
      display: block;
      width: 100%;
    }
  }

  @include HOVER {
    .img-zoom > img,
    .js-image {
      @include add_prefix(transition, 0.7s ease all);
    }
    &:hover {
      opacity: 1;
      .img-zoom > img,
      .js-image {
        transform: scale(1.15);
      }
      .cm-arr02 {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
}
.js-hover-parent {
  @include HOVER {
    &.is-active {
      opacity: 1;
      .img-zoom > img,
      .js-image {
        transform: scale(1.15);
      }
      .cm-arr02 {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
}
/* TITLE
-------------------------------------------------------------*/
.keyvisual {
  position: relative;
  overflow: hidden;
  background: url($url_img_wpcontent+'common/f_bg.jpg') no-repeat center center;
  background-size: cover;
  height: 254px;
  .container {
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(center);
    height: 100%;
    .keyvisual-ttl {
      width: 100%;
      @include font(36,45,400,500);
      @include ffYM;
      color: #fff;
      text-align: left;
      position: relative;
      &:before {
        position: absolute; content: "";
        right: 100%; margin: -1px 26px 0 0;
        width: 50vw; height: 1px;
        background: #fff;
        top: 50%;
      }
    }
  }
  @include SP {
    height: 128px;
    .container {
      .keyvisual-ttl {
        width: 100%;
        @include font(28,35);
        padding-left: 46px;
        @include IP5 {
          padding-left: 33px;
          @include font(26,35,140);
        }
        &:before {
          left: -21px; right: auto;
          margin: -1px 0 0 0;
          width: 43px;
          @include IP5 {
            width: 35px;
          }
        }
      }
    }
  }
}

.main-content {
  position: relative;
  &.has-texture1 {
    padding-top: 122px;
    &:before {
      position: absolute; content: "";
      right: -15px; top: 35px;
      width: 284px; height: 171px;
      background: url($url_img_wpcontent+'common/texture1.png') no-repeat center center;
      background-size: 100% auto;
      @include min-screen(1366px) {
        right: 50%; top: 35px; margin-right: -695px;
      }
    }
  }
  @include SP {
    &.has-texture1 {
      padding-top: 46px;
      &:before {
        // right: -15px; top: 35px;
        // width: 284px; height: 171px;
      }
    }

  }
}

.cm-ttl01 {
  position: relative;
  text-align: center;
  color: $color_black;
  @include ffYM;
  @include font(30,47,200,500);
  &.hasline {
    text-align: center;
    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 0 58px;
      &:before, &:after {
        position: absolute; content: "";
        top: 50%; margin-top: -1px;
        background: $color_black;
        width: 27px; height: 1px;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
  }
  @include SP {
    &.hasline {
      span {
        padding: 0 39px;
      }
    }
    @include font(26,47);
    @include ANDR {
      @include font(25,45);
    }
    @include IP5 {
      @include font(23,43);
    }
  }
}

.cm-ttl02 {
  position: relative;
  color: $color_black;
  @include ffYM;
  @include font(24,47,200,500);
  &:before {
    position: absolute; content: "";
    top: 22px; right: 100%;
    background: $color_black;
    width: 140px; height: 1px;
    margin-right: 30px;
  }
  @include SP {
    padding-left: 25px;
    &:before {
      left: 0; top: 20px;
      width: 15px;
      margin-right: 0;
    }
    @include font(20,42);
    @include ANDR {
      @include font(18,40);
    }
    @include IP5 {
      @include font(17,38);
    }
  }
}

.cm-txt01 {
  @include font(14,28,80,400);
}
/* BUTTON
-------------------------------------------------------------*/
.new-label {
  position: absolute;
  left: 0; top: 0;
  z-index: 87;
}

.cm-tel-sp {
  @include PC {
    cursor: default;
    pointer-events: none;
    text-decoration: none;
  }
}
.cm-arr01 {
  position: relative;
  display: inline-block;
  width: 85px; height: 8px;
  &:before, &:after {
    position: absolute; content: "";
    bottom: 0;
    background: $color_dark_blue;
    height: 1px;
  }
  &:before {
    left: 0; width: 100%;
  }
  &:after {
    right: 0; width: 10px;
    @include transform(rotate(40deg));
    transform-origin: bottom right;
  }
  @include SP {
    width: 56px;
  }
}
.cm-arr02 {
  position: relative;
  display: inline-block;
  width: 43px; height: 3px;
  &:before, &:after {
    position: absolute; content: "";
    bottom: 0;
    background: $color_dark_blue;
    height: 1px;
  }
  &:before {
    left: 0; width: 100%;
  }
  &:after {
    right: 0; width: 6px;
    @include transform(rotate(30deg));
    transform-origin: bottom right;
  }
  @include SP {
    width: 28px;
  }
}
.cm-arr03 {
  position: relative;
  display: inline-block;
  width: 85px; height: 8px;
  &:before, &:after {
    position: absolute; content: "";
    bottom: 0;
    background: $color_dark_blue;
    height: 1px;
  }
  &:before {
    left: 0; width: 100%;
  }
  &:after {
    left: 0; width: 10px;
    @include transform(rotate(-40deg));
    transform-origin: bottom left;
  }
  @include SP {
    width: 56px;
  }
}

.cm-btn01 {
  display: inline-block;
  color: $color_dark_blue;
  text-decoration: underline;
  position: relative;
  @include ffBenne;
  @include font(14,14,80,400);
  vertical-align: middle;
  @include add_prefix(transition, 0.3s ease all);
  &:link, &:visited, &:active {
    text-decoration: underline;
  }
  .cm-arr02 {
    margin: 0 0 1px 7px;
    opacity: 0;
    @include transform(translateX(-10px));
    @include add_prefix(transition, 0.3s ease all);
  }
  @include SP {
    .cm-arr02 {
      opacity: 1;
      @include transform(translateX(0px));
    }
  }
  @include HOVER {
    &:hover {
      .cm-arr02 {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
}

.cm-btn02 {
  position: relative;
  display: block;
  text-align: center;
  color: $color_black;
  border: 1px solid $color_black;
  text-decoration: underline;
  @include ffYM;
  @include font(16,24,200,400);
  width: 100%;
  max-width: 315px;
  padding: 17px 20px;
  @include add_prefix(transition, 0.3s ease all);
  @include SP {
    max-width: 280px;
    background: #fff;
    @include ANDR {
      padding: 16px 20px;
      max-width: 260px;
    }
    @include IP5 {
      padding: 15px 20px;
      max-width: 240px;
    }
  }
  &.center {
    margin: 0 auto;
  }
  &:after {
    position: absolute; content: "";
    bottom: 0; left: 0;
    background: $color_dark_blue;
    height: 2px; width: 100%;
    opacity: 0;
    @include add_prefix(transition, 0.3s ease all);
  }
  .cm-arr01, .cm-arr03 {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    opacity: 0;
    width: 85px;
    @include add_prefix(transition, 0.3s ease all);
  }
  .cm-arr01 {
    right: -43px;
    @include transform(translateX(-20px));
  }
  .cm-arr03 {
    left: -43px;
    @include transform(translateX(20px));
  }
  @include SP {
    .cm-arr01, .cm-arr03 {
      opacity: 1;
      margin-top: -7px;
      width: 56px;
      @include transform(translateX(0px));
      @include IP5 {
        width: 52px;
      }
    }
    .cm-arr01 {
      right: -27px;
      @include IP5 {
        right: -25px;
      }
    }
    .cm-arr03 {
      left: -27px;
      @include IP5 {
        left: -25px;
      }
    }
    &:after {
      opacity: 1;
    }
  }
  @include HOVER {
    &:hover {
      opacity: 1;
      background: #fff;
      &:after {
        opacity: 1;
      }
      .cm-arr01, .cm-arr03 {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
}

.btn-shoping {
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 315px;
  border: 1px solid #fff;
  padding: 15px 10px 15px 8px;
  span {
    position: relative;
    color: #fff;
    @include font(16,22,200,400);
    @include ffYM;
    padding-left: 41px;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      width: 17px;
      height: 16px;
      background: url($url_img_wpcontent+'common/icon/ico_cart.svg') no-repeat 0 0;
      background-size: 100% 100%;
      @include add_prefix(transition, 0.2s ease all);
      margin-top: -8px;
    }
  }
}

.cm-cats {
  span {
    color: #fff;
    background: $color_dark_blue;
    @include font(12,16,80,500);
    display: inline-block;
    text-align: center;
    padding: 2px 2px;
  }
}
.cm-tags {
  @include font(12,15,80,400);
  span, a {
    @include font(12,15);
    color: #969696;
    margin: 0 20px 5px 0;
    display: inline-block;
    text-decoration: underline;
  }
}
/* ORTHER
-------------------------------------------------------------*/
.cm-bg-gray01 {
  background: $color_gray;
}

.accordion-box {
  display: none;
}


.accordion-boxSP {
  @include PC {
    display: block!important;
  }
  @include SP {
    display: none;
  }
}

.sec-category {
  background: $color_gray;
  padding: 60px 0 44px 113px;
  margin-bottom: 92px;
  .ttl {
    width: 100%;
    @include font(24,35,200,500);
    @include ffYM;
    text-align: left;
    position: relative;
    &:before {
      position: absolute; content: "";
      right: 100%; margin: -2px 42px 0 0;
      width: 139px; height: 1px;
      background: #333333;
      top: 50%;
    }
  }
  .list {
    margin-top: 22px;
    li {
      display: inline-block;vertical-align: top;
      margin: 13px 45px 13px 0;
      a {
        @include font(14,28,80,500);
        position: relative;
        display: inline-block;
        padding-left: 20px;
        &:before {
          position: absolute; content: "";
          left: 0; margin: -1px 0 0;
          width: 8px; height: 1px;
          background: #333333;
          top: 50%;
        }
      }
    }
  }

  @include SP {
    background: none;
    padding: 0px;
    margin-bottom: 52px;
    position: relative;
    .ttl {
      width: 100%;
      @include font(16,24);
      background: #fff;
      border: 1px solid $color_black;
      border-bottom: 3px solid $color_black;
      padding: 17px 20px;
      text-align: center;
      &:before {
        background: none;
        right: 17px;
        top: 50%;
        width: 5px;
        height: 5px;
        border-left: 1px solid $color_black;
        border-top: 1px solid $color_black;
        @include  transform(rotate(225deg));
        @include add_prefix(transition, 0.3s ease all);
        margin: -3px 0 0;
      }
      &.is-active {
        &:before {
          @include  transform(rotate(45deg));
          margin: -2px 0 0;
        }
      }
    }
    .list {
      margin-top: 0px;
      border: 1px solid $color_black;
      border-top: 0;
      border-bottom: 0;
      top: 100%;
      background: #fff;
      z-index: 999;
      width: 100%;
      li {
        margin: 0;
        display: block;
        width: 100%;
        a {
          border-bottom: 1px solid #333;
          display: block;
          padding: 7px 19px 6px;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
